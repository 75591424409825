<template>
  <div class="tableDetailNews">
    <div class="tableDetailNews__content">
      <div v-if="typeNews==='searChsan'">
        <!-- 制裁名單類別 -->
        <el-row class="tableDetailNews__content--SanList mt-8">
          <el-col v-for="item in SanList" :key="item.ListID">
            <label for="" class="label">制裁名單類別：</label>
            <table>
              <tbody>
                <tr>
                  <th class="tableHead">序號</th>
                  <td class="tableBody">{{ item.DataID }}</td>
                </tr>
                <tr>
                  <th class="tableHead">來源名稱</th>
                  <td class="tableBody">{{ item.SourceName }}</td>
                </tr>
                <tr>
                  <th class="tableHead">名單分類</th>
                  <td class="tableBody">{{ item.ListType }}</td>
                </tr>
                <tr>
                  <th class="tableHead">名單編號</th>
                  <td class="tableBody">{{ item.ListID }}</td>
                </tr>
                <tr>
                  <th class="tableHead">版本編號</th>
                  <td class="tableBody">{{ item.VersionNm }}</td>
                </tr>
                <tr>
                  <th class="tableHead">第一個名字</th>
                  <td class="tableBody">{{ item.FirstName }}</td>
                </tr>
                <tr>
                  <th class="tableHead">第二個名字</th>
                  <td class="tableBody">{{ item.SecondName }}</td>
                </tr>
                <tr>
                  <th class="tableHead">第三個名字</th>
                  <td class="tableBody">{{ item.ThirdName }}</td>
                </tr>
                <tr>
                  <th class="tableHead">第四個名字</th>
                  <td class="tableBody">{{ item.FourthName }}</td>
                </tr>
                <tr>
                  <th class="tableHead">聯合國名單種類</th>
                  <td class="tableBody">{{ item.UNListType }}</td>
                </tr>
                <tr>
                  <th class="tableHead">參考編號</th>
                  <td class="tableBody">{{ item.ReferenceNm }}</td>
                </tr>
                <tr>
                  <th class="tableHead">發布時間</th>
                  <td class="tableBody">{{ item.PublicDt }}</td>
                </tr>
                <tr>
                  <th class="tableHead">原始名稱</th>
                  <td class="tableBody">{{ item.OriginalName }}</td>
                </tr>
                <tr>
                  <th class="tableHead">性別</th>
                  <td class="tableBody">{{ item.Gender }}</td>
                </tr>
                <tr>
                  <th class="tableHead">提交者</th>
                  <td class="tableBody">{{ item.Submit }}</td>
                </tr>
                <tr>
                  <th class="tableHead">註釋</th>
                  <td class="tableBody">{{ item.Comment }}</td>
                </tr>
                <tr>
                  <th class="tableHead">資料日期</th>
                  <td class="tableBody">{{ item.DataDt }}</td>
                </tr>
              </tbody>
            </table>
          </el-col>
        </el-row>
        <!-- 制裁名單住址 -->
        <el-row class="mt-8">
          <el-col>
            <label class="label">制裁名單住址：</label>
            <el-table border style="width: 100%" max-height="400"
              :data="tableData.SanList_Address"
            >
              <el-table-column prop="DataID" label="序號"> </el-table-column>
              <el-table-column prop="ListID" label="名單編號"> </el-table-column>
              <el-table-column prop="Street" label="街道"> </el-table-column>
              <el-table-column prop="City" label="城市"> </el-table-column>
              <el-table-column prop="ZipCode" label="郵區編號"> </el-table-column>
              <el-table-column prop="Country" label="國家"> </el-table-column>
              <el-table-column prop="StateProvince" label="州省"></el-table-column>
              <el-table-column prop="Memo" label="註釋"> </el-table-column>
              <el-table-column prop="DataDt" label="資料日期">
              </el-table-column>
            </el-table
          ></el-col>
        </el-row>
         <!-- 制裁名單別名 -->
        <el-row class="mt-8">
          <el-col>
            <label class="label">制裁名單別名：</label>
            <el-table border :data="tableData.SanList_Alias" max-height="400" style="width: 100%">
              <el-table-column prop="DataID" label="序號"></el-table-column>
              <el-table-column prop="ListID" label="名單編號"></el-table-column>
              <el-table-column prop="Quality" label="別名品質"></el-table-column>
              <el-table-column prop="AliasName" label="別名名稱"></el-table-column>
              <el-table-column prop="BornDt" label="出生日期"></el-table-column>
              <el-table-column prop="BornCity" label="出生城市"></el-table-column>
              <el-table-column prop="BornCountry" label="出生國家"></el-table-column>
              <el-table-column prop="Memo" label="註釋"></el-table-column>
              <el-table-column prop="DataDt" label="資料日期"></el-table-column> 
            </el-table
          ></el-col>
        </el-row>
        <!-- 制裁名單生日 -->
        <el-row class="mt-8">
          <el-col>
            <label for="" class="label">制裁名單生日：</label>
            <el-table border :data="tableData.SanList_Birth" style="width: 100%" max-height="400">
              <el-table-column prop="BornDt" label="出生日期"></el-table-column>
              <el-table-column prop="BornEnd" label="出生區間_迄"></el-table-column>
              <el-table-column prop="BornStart" label="出生區間_起"></el-table-column>
              <el-table-column prop="BornYear" label="出生年份"></el-table-column>
              <el-table-column prop="DataDt" label="資料日期"></el-table-column> 
              <el-table-column prop="DataID" label="序號"></el-table-column>
              <el-table-column prop="DtType" label="日期種類"></el-table-column>
              <el-table-column prop="ListID" label="名單編號"></el-table-column>
              <el-table-column prop="Memo" label="註釋"></el-table-column>
            </el-table>
          </el-col>
        </el-row>
        <!-- 制裁名單稱號 -->
        <el-row class="mt-8">
          <el-col :span="12">
            <label class="label">制裁名單稱號：</label>
            <el-table border style="width: 90%" max-height="400"
              :data="tableData.SanList_Designation"
            >
              <el-table-column prop="DataID" label="序號"></el-table-column>
              <el-table-column prop="ListID" label="名單編號"></el-table-column>
              <el-table-column prop="Designation" label="名單稱號"></el-table-column>
              <el-table-column prop="DataDt" label="資料日期"></el-table-column> 
            </el-table>
          </el-col>
        </el-row>
        <!-- 制裁名單文件 -->
        <el-row class="mt-8">
          <el-col>
            <label for="" class="label">制裁名單文件 ：</label>
            <el-table  border style="width: 100%" max-height="400"
              :data="tableData.SanList_Document"
            >
              <el-table-column prop="DataID" label="序號"></el-table-column>
              <el-table-column prop="ListID" label="名單編號"></el-table-column>
              <el-table-column prop="DocType1" label="文件種類"></el-table-column>
              <el-table-column prop="DocType2" label="文件種類2"></el-table-column>
              <el-table-column prop="Nm" label="號碼"></el-table-column>
              <el-table-column prop="IssueDt" label="發行日期"></el-table-column>
              <el-table-column prop="IssueCity" label="發行城市"></el-table-column>
              <el-table-column prop="IssueCountry" label="發行國家"></el-table-column>
              <el-table-column prop="IssuingCountry" label="正在發行國家"></el-table-column>
              <el-table-column prop="Memo" label="註釋"></el-table-column>
              <el-table-column prop="DataDt" label="資料日期"></el-table-column>
            </el-table>
          </el-col>
        </el-row>
        <!-- 制裁名單國籍 -->
        <el-row class="mt-8">
          <el-col :span="12">
            <label for="" class="label">制裁名單國籍：</label>
            <el-table border style="width: 90%" max-height="400"
              :data="tableData.SanList_Nationality"
            >
              <el-table-column prop="DataID" label="序號"> </el-table-column>
              <el-table-column prop="ListID" label="名單編號"> </el-table-column>
              <el-table-column prop="Nationality" label="國籍"> </el-table-column>
              <el-table-column prop="DataDt" label="資料日期"> </el-table-column>
            </el-table>
          </el-col>
        </el-row>
        <!-- 制裁名單出生地：-->
        <el-row class="mt-8">
          <el-col :span="12">
            <label for="" class="label">制裁名單出生地：</label>
            <el-table border :data="tableData.SanList_Place" style="width: 90%" max-height="400">
              <el-table-column prop="DataID" label="序號"></el-table-column>
              <el-table-column prop="ListID" label="名單編號"></el-table-column>
              <el-table-column prop="City" label="城市 "></el-table-column>
              <el-table-column prop="Country" label="出生國家"> </el-table-column>
              <el-table-column prop="StateProvince" label="州省"></el-table-column>
              <el-table-column prop="DataDt" label="資料日期"> </el-table-column>
            </el-table>
          </el-col>
        </el-row>
        <!-- 制裁名單稱謂：-->
        <el-row class="mt-8">
          <el-col :span="12">
            <label class="label">制裁名單稱謂：</label>
            <el-table border :data="tableData.SanList_Title" style="width: 90%" max-height="400">
              <el-table-column prop="DataID" label="序號"> </el-table-column>
              <el-table-column prop="ListID" label="名單編號"> </el-table-column>
              <el-table-column prop="Title" label="名單稱謂"> </el-table-column>
              <el-table-column prop="DataDt" label="資料日期">
              </el-table-column> 
            </el-table>
          </el-col>
        </el-row>
        <!-- 制裁名單類別-->
        <el-row class="mt-8">
          <el-col :span="12">
            <label for="" class="label">制裁名單類別：</label>
            <el-table border :data="tableData.SanList_Type" style="width: 90%" max-height="400">
              <el-table-column prop="DataID" label="序號"></el-table-column>
              <el-table-column prop="ListID" label="名單編號"></el-table-column>
              <el-table-column prop="ListType" label="名單分類"></el-table-column>
              <el-table-column prop="DataDt" label="資料日期"> </el-table-column>
            </el-table>
          </el-col>
        </el-row>
        <!-- 制裁名單更新時間&制裁名單稱謂 -->
        <el-row class="mt-8">
          <el-col :span="12">
            <label for="" class="label">制裁名單更新時間：</label>
            <el-table border :data="tableData.SanList_Updated" style="width: 90%" max-height="400">
              <el-table-column prop="DataID" label="序號"></el-table-column>
              <el-table-column prop="ListID" label="名單編號"></el-table-column>
              <el-table-column prop="LastMtDt" label="最後更新時間"></el-table-column>
              <el-table-column prop="DataDt" label="資料日期">
              </el-table-column> 
            </el-table>
          </el-col>
        </el-row>
      </div>
      <!-- *政府拒絕往來廠商名單* -->
      <div v-if="typeNews==='searchConstr'">
        <el-table :data="tableData" style="width: 100%" max-height="400">
          <el-table-column prop="UniComNm" label="廠商代碼" width="100"></el-table-column>
          <el-table-column prop="CaseNm" label="標案案號" width="120"></el-table-column>
          <el-table-column prop="ComName" label="廠商名稱" width="150"></el-table-column>
          <el-table-column prop="ComLoc" label="廠商地址" width="200"></el-table-column>
          <el-table-column prop="ComCountry" label="廠商國別" width="150"></el-table-column>
          <el-table-column prop="PublicAuthNm" label="刊登機關代碼" width="150"></el-table-column>
          <el-table-column prop="PublicAuthName" label="刊登機關名稱" width="150"></el-table-column>
          <el-table-column prop="AuthLoc" label="機關地址" width="200"></el-table-column>
          <el-table-column prop="ContactPerson" label="聯絡人" width="150"></el-table-column>
          <el-table-column prop="ContactPhone" label="聯絡電話" width="150"></el-table-column>
          <el-table-column prop="CaseName" label="標案名稱" width="150"></el-table-column>
          <el-table-column prop="GPActStatus" label="符合政府採購法第101條所列之情形" width="400"></el-table-column>
          <el-table-column prop="OrigPublicDt" label="原始公告日期" width="120"></el-table-column>
          <el-table-column prop="PublicDt" label="公告日期" width="120"></el-table-column>
          <el-table-column prop="EffectDt" label="拒絕往來生效日" width="120"></el-table-column>
          <el-table-column prop="GPActPeriod" label="政府採購法第103條第一項所規定之期間" width="300"></el-table-column>
          <el-table-column prop="DeadlineDt" label="拒絕往來截止日" width="120"></el-table-column>
          <el-table-column prop="AppealResult" label="異議或申訴結果" width="400"></el-table-column>
        </el-table>
      </div>
      <div v-if="typeNews==='searchLabor'">
        <el-table :data="tableData" style="width: 100%" max-height="400">
          <el-table-column prop="MgrAuth" label="主管機關" width="120"></el-table-column>
          <el-table-column prop="PublicDt" label="公告日期" width="150"></el-table-column>
          <el-table-column prop="PunishDt" label="處分日期" width="150"></el-table-column>
          <el-table-column prop="PunishNm" label="處分字號" width="180"></el-table-column>
          <el-table-column prop="UnitEntity" label="事業單位名稱" width="180"></el-table-column>
          <el-table-column prop="NameEntity" label="負責人 / 自然人姓名" width="180"></el-table-column>
          <el-table-column prop="VioLaw" label="違法法規法條" width="180"></el-table-column>
          <el-table-column prop="VioCont" label="違反法規內容" width="350"></el-table-column>
          <el-table-column prop="FineAmount" label="罰鍰金額" width="150"></el-table-column>
          <el-table-column prop="Memo" label="備註說明" width="350"></el-table-column>
        </el-table>
      </div>
      <div v-if="typeNews==='searchEnviroment'">
        <el-table :data="tableData" style="width: 100%" max-height="400">
          <el-table-column prop="UnitName" label="行為人名稱" width="120"></el-table-column>
          <el-table-column prop="PunishTime" label="違反處分時間" width="120"></el-table-column>
          <el-table-column prop="CaseName" label="名單名稱" width="250"></el-table-column>
          <el-table-column prop="VioFact" label="違反事實" width="250"></el-table-column>
          <el-table-column prop="VioLaw" label="違反法令" width="300"></el-table-column>
          <el-table-column prop="FineAmount" label="裁處金額-元" width="150"></el-table-column>
          <el-table-column prop="IsAppeal" label="是否訴願" width="100"></el-table-column>
          <el-table-column prop="AppealResult" label="訴願結果" width="300"></el-table-column>
          <el-table-column prop="RestrictTime" label="限制時間" width="120"></el-table-column>
          <el-table-column prop="IsImprove" label="是否已改善"></el-table-column>
        </el-table>
      </div>
    </div>
    <div class="tableDetailNews__button">
      <el-button v-if="isOperator" type="primary" @click="toggleDialog">
        <font-awesome-icon icon="plus" style="margin-right: 5px"/>建立警示名單
      </el-button>
      <el-button type="info" @click="closeDetail()">關閉</el-button>
    </div>
    <div v-if="openPopUp" class="WarningListPopUp">
      <div class="popup">
        <i class="el-icon-close" @click="toggleDialog"></i>
        <p>請選擇要新增的名單類別</p>
        <div>
          <el-button type="danger" @click="routerPush('people')">人名</el-button>
          <el-button type="danger" @click="routerPush('org')">組織</el-button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Breadcrumb from "../../src/components/Breadcrumb.vue";
export default {
  components: { Breadcrumb },
  data() {
    return {
      openPopUp: false,
      userRole: this.$store.state.userInfo.Role,
      tableData: [],
      SanList: [],
      typeNews:this.$route.query.typeNews,
      dataId:this.$route.query.DataID,
    };
  },
  computed:{
    isOperator(){
      let result = null;
      if(this.userRole==='operator'){
        result = true
      }else{
         result = false
      }
      return result
    },
  },
  methods: {
    toggleDialog() {
      this.openPopUp = !this.openPopUp;
    },
    routerPush(formType) {
      let manageAllSelection = {
        searchNews:[],
        searChsan:[],
        searchConstr:[],
        searchLabor:[],
        searchEnviroment:[]
      }
      if(this.typeNews==='searChsan'){
        manageAllSelection.searChsan.push(this.SanList[0])
      }else{
        manageAllSelection[this.typeNews].push(this.tableData[0])
      }
      console.log(manageAllSelection);
      this.$router.push({
        name: "warningListAdd",
        query: {
          caseStatus:'add',
          warningFormType: formType,//people或 org
          typeNews: this.typeNews,
          DataID:JSON.stringify(manageAllSelection),
          routerName:'tableDetailNews'
        }
      });
    },
    closeDetail() {
      this.$confirm("確定要關閉此頁面嗎？", "關閉提示", {
        confirmButtonText: "確定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        window.close();
      });
    },
    getList() {
      this.$store.dispatch("loadingHandler", true);
      const listQuery = {
        DataID: [this.dataId],
        Page_Size: 1,
        Page_Num: 1,
        IsUser:true
      };
      this.$api.data[this.typeNews](listQuery).then((res)=>{
        if(this.typeNews==='searChsan'){
          this.SanList.push(res.data[0].SanList);
          this.tableData = res.data[0];
        }else{
          this.tableData = res.data
        }
        this.$store.dispatch("loadingHandler", false);
      })
    },
  },
  mounted() {
    this.getList();
  },
};
</script>

<style lang="scss" scoped>
.tableDetailNews {
  width: 100%;
  min-height: 100vh;
  background: linear-gradient(-135deg, #00abb9 20%, #fff 50%, transparent 50%)
    center center / 100% 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  &__content {
    padding: 20px;
    &--SanList {
      margin-top: 20px;
      table {
        margin-top: 4px;
        border-collapse: collapse;
        width: 100%;
      }
      td,
      th {
        border: 1px solid #000;
        text-align: left;
        padding: 8px;
      }
    }
    .mt-8 {
      margin-top: 32px;
      .label {
        font-weight: 600;
        font-size: 16px;
      }
    }
  }
  &__button {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 0 20px 0;
  }
  .WarningListPopUp {
    width: 100%;
    height: 100vh;
    background-color: rgba(133, 133, 133, 0.7);
    position: fixed;
    z-index: 9999999;
    left: 0;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    .popup {
      position: relative;
      text-align: center;
      width: 20%;
      padding: 5%;
      background-color: white;
      border-radius: 10px;
      .el-icon-close{
        cursor: pointer;
        position: absolute;
        top: 10px;
        right: 10px;
      }
    }
  }
}
</style>
