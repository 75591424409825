var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "tableDetailNews" }, [
    _c("div", { staticClass: "tableDetailNews__content" }, [
      _vm.typeNews === "searChsan"
        ? _c(
            "div",
            [
              _c(
                "el-row",
                { staticClass: "tableDetailNews__content--SanList mt-8" },
                _vm._l(_vm.SanList, function(item) {
                  return _c("el-col", { key: item.ListID }, [
                    _c("label", { staticClass: "label", attrs: { for: "" } }, [
                      _vm._v("制裁名單類別：")
                    ]),
                    _c("table", [
                      _c("tbody", [
                        _c("tr", [
                          _c("th", { staticClass: "tableHead" }, [
                            _vm._v("序號")
                          ]),
                          _c("td", { staticClass: "tableBody" }, [
                            _vm._v(_vm._s(item.DataID))
                          ])
                        ]),
                        _c("tr", [
                          _c("th", { staticClass: "tableHead" }, [
                            _vm._v("來源名稱")
                          ]),
                          _c("td", { staticClass: "tableBody" }, [
                            _vm._v(_vm._s(item.SourceName))
                          ])
                        ]),
                        _c("tr", [
                          _c("th", { staticClass: "tableHead" }, [
                            _vm._v("名單分類")
                          ]),
                          _c("td", { staticClass: "tableBody" }, [
                            _vm._v(_vm._s(item.ListType))
                          ])
                        ]),
                        _c("tr", [
                          _c("th", { staticClass: "tableHead" }, [
                            _vm._v("名單編號")
                          ]),
                          _c("td", { staticClass: "tableBody" }, [
                            _vm._v(_vm._s(item.ListID))
                          ])
                        ]),
                        _c("tr", [
                          _c("th", { staticClass: "tableHead" }, [
                            _vm._v("版本編號")
                          ]),
                          _c("td", { staticClass: "tableBody" }, [
                            _vm._v(_vm._s(item.VersionNm))
                          ])
                        ]),
                        _c("tr", [
                          _c("th", { staticClass: "tableHead" }, [
                            _vm._v("第一個名字")
                          ]),
                          _c("td", { staticClass: "tableBody" }, [
                            _vm._v(_vm._s(item.FirstName))
                          ])
                        ]),
                        _c("tr", [
                          _c("th", { staticClass: "tableHead" }, [
                            _vm._v("第二個名字")
                          ]),
                          _c("td", { staticClass: "tableBody" }, [
                            _vm._v(_vm._s(item.SecondName))
                          ])
                        ]),
                        _c("tr", [
                          _c("th", { staticClass: "tableHead" }, [
                            _vm._v("第三個名字")
                          ]),
                          _c("td", { staticClass: "tableBody" }, [
                            _vm._v(_vm._s(item.ThirdName))
                          ])
                        ]),
                        _c("tr", [
                          _c("th", { staticClass: "tableHead" }, [
                            _vm._v("第四個名字")
                          ]),
                          _c("td", { staticClass: "tableBody" }, [
                            _vm._v(_vm._s(item.FourthName))
                          ])
                        ]),
                        _c("tr", [
                          _c("th", { staticClass: "tableHead" }, [
                            _vm._v("聯合國名單種類")
                          ]),
                          _c("td", { staticClass: "tableBody" }, [
                            _vm._v(_vm._s(item.UNListType))
                          ])
                        ]),
                        _c("tr", [
                          _c("th", { staticClass: "tableHead" }, [
                            _vm._v("參考編號")
                          ]),
                          _c("td", { staticClass: "tableBody" }, [
                            _vm._v(_vm._s(item.ReferenceNm))
                          ])
                        ]),
                        _c("tr", [
                          _c("th", { staticClass: "tableHead" }, [
                            _vm._v("發布時間")
                          ]),
                          _c("td", { staticClass: "tableBody" }, [
                            _vm._v(_vm._s(item.PublicDt))
                          ])
                        ]),
                        _c("tr", [
                          _c("th", { staticClass: "tableHead" }, [
                            _vm._v("原始名稱")
                          ]),
                          _c("td", { staticClass: "tableBody" }, [
                            _vm._v(_vm._s(item.OriginalName))
                          ])
                        ]),
                        _c("tr", [
                          _c("th", { staticClass: "tableHead" }, [
                            _vm._v("性別")
                          ]),
                          _c("td", { staticClass: "tableBody" }, [
                            _vm._v(_vm._s(item.Gender))
                          ])
                        ]),
                        _c("tr", [
                          _c("th", { staticClass: "tableHead" }, [
                            _vm._v("提交者")
                          ]),
                          _c("td", { staticClass: "tableBody" }, [
                            _vm._v(_vm._s(item.Submit))
                          ])
                        ]),
                        _c("tr", [
                          _c("th", { staticClass: "tableHead" }, [
                            _vm._v("註釋")
                          ]),
                          _c("td", { staticClass: "tableBody" }, [
                            _vm._v(_vm._s(item.Comment))
                          ])
                        ]),
                        _c("tr", [
                          _c("th", { staticClass: "tableHead" }, [
                            _vm._v("資料日期")
                          ]),
                          _c("td", { staticClass: "tableBody" }, [
                            _vm._v(_vm._s(item.DataDt))
                          ])
                        ])
                      ])
                    ])
                  ])
                }),
                1
              ),
              _c(
                "el-row",
                { staticClass: "mt-8" },
                [
                  _c(
                    "el-col",
                    [
                      _c("label", { staticClass: "label" }, [
                        _vm._v("制裁名單住址：")
                      ]),
                      _c(
                        "el-table",
                        {
                          staticStyle: { width: "100%" },
                          attrs: {
                            border: "",
                            "max-height": "400",
                            data: _vm.tableData.SanList_Address
                          }
                        },
                        [
                          _c("el-table-column", {
                            attrs: { prop: "DataID", label: "序號" }
                          }),
                          _c("el-table-column", {
                            attrs: { prop: "ListID", label: "名單編號" }
                          }),
                          _c("el-table-column", {
                            attrs: { prop: "Street", label: "街道" }
                          }),
                          _c("el-table-column", {
                            attrs: { prop: "City", label: "城市" }
                          }),
                          _c("el-table-column", {
                            attrs: { prop: "ZipCode", label: "郵區編號" }
                          }),
                          _c("el-table-column", {
                            attrs: { prop: "Country", label: "國家" }
                          }),
                          _c("el-table-column", {
                            attrs: { prop: "StateProvince", label: "州省" }
                          }),
                          _c("el-table-column", {
                            attrs: { prop: "Memo", label: "註釋" }
                          }),
                          _c("el-table-column", {
                            attrs: { prop: "DataDt", label: "資料日期" }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-row",
                { staticClass: "mt-8" },
                [
                  _c(
                    "el-col",
                    [
                      _c("label", { staticClass: "label" }, [
                        _vm._v("制裁名單別名：")
                      ]),
                      _c(
                        "el-table",
                        {
                          staticStyle: { width: "100%" },
                          attrs: {
                            border: "",
                            data: _vm.tableData.SanList_Alias,
                            "max-height": "400"
                          }
                        },
                        [
                          _c("el-table-column", {
                            attrs: { prop: "DataID", label: "序號" }
                          }),
                          _c("el-table-column", {
                            attrs: { prop: "ListID", label: "名單編號" }
                          }),
                          _c("el-table-column", {
                            attrs: { prop: "Quality", label: "別名品質" }
                          }),
                          _c("el-table-column", {
                            attrs: { prop: "AliasName", label: "別名名稱" }
                          }),
                          _c("el-table-column", {
                            attrs: { prop: "BornDt", label: "出生日期" }
                          }),
                          _c("el-table-column", {
                            attrs: { prop: "BornCity", label: "出生城市" }
                          }),
                          _c("el-table-column", {
                            attrs: { prop: "BornCountry", label: "出生國家" }
                          }),
                          _c("el-table-column", {
                            attrs: { prop: "Memo", label: "註釋" }
                          }),
                          _c("el-table-column", {
                            attrs: { prop: "DataDt", label: "資料日期" }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-row",
                { staticClass: "mt-8" },
                [
                  _c(
                    "el-col",
                    [
                      _c(
                        "label",
                        { staticClass: "label", attrs: { for: "" } },
                        [_vm._v("制裁名單生日：")]
                      ),
                      _c(
                        "el-table",
                        {
                          staticStyle: { width: "100%" },
                          attrs: {
                            border: "",
                            data: _vm.tableData.SanList_Birth,
                            "max-height": "400"
                          }
                        },
                        [
                          _c("el-table-column", {
                            attrs: { prop: "BornDt", label: "出生日期" }
                          }),
                          _c("el-table-column", {
                            attrs: { prop: "BornEnd", label: "出生區間_迄" }
                          }),
                          _c("el-table-column", {
                            attrs: { prop: "BornStart", label: "出生區間_起" }
                          }),
                          _c("el-table-column", {
                            attrs: { prop: "BornYear", label: "出生年份" }
                          }),
                          _c("el-table-column", {
                            attrs: { prop: "DataDt", label: "資料日期" }
                          }),
                          _c("el-table-column", {
                            attrs: { prop: "DataID", label: "序號" }
                          }),
                          _c("el-table-column", {
                            attrs: { prop: "DtType", label: "日期種類" }
                          }),
                          _c("el-table-column", {
                            attrs: { prop: "ListID", label: "名單編號" }
                          }),
                          _c("el-table-column", {
                            attrs: { prop: "Memo", label: "註釋" }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-row",
                { staticClass: "mt-8" },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c("label", { staticClass: "label" }, [
                        _vm._v("制裁名單稱號：")
                      ]),
                      _c(
                        "el-table",
                        {
                          staticStyle: { width: "90%" },
                          attrs: {
                            border: "",
                            "max-height": "400",
                            data: _vm.tableData.SanList_Designation
                          }
                        },
                        [
                          _c("el-table-column", {
                            attrs: { prop: "DataID", label: "序號" }
                          }),
                          _c("el-table-column", {
                            attrs: { prop: "ListID", label: "名單編號" }
                          }),
                          _c("el-table-column", {
                            attrs: { prop: "Designation", label: "名單稱號" }
                          }),
                          _c("el-table-column", {
                            attrs: { prop: "DataDt", label: "資料日期" }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-row",
                { staticClass: "mt-8" },
                [
                  _c(
                    "el-col",
                    [
                      _c(
                        "label",
                        { staticClass: "label", attrs: { for: "" } },
                        [_vm._v("制裁名單文件 ：")]
                      ),
                      _c(
                        "el-table",
                        {
                          staticStyle: { width: "100%" },
                          attrs: {
                            border: "",
                            "max-height": "400",
                            data: _vm.tableData.SanList_Document
                          }
                        },
                        [
                          _c("el-table-column", {
                            attrs: { prop: "DataID", label: "序號" }
                          }),
                          _c("el-table-column", {
                            attrs: { prop: "ListID", label: "名單編號" }
                          }),
                          _c("el-table-column", {
                            attrs: { prop: "DocType1", label: "文件種類" }
                          }),
                          _c("el-table-column", {
                            attrs: { prop: "DocType2", label: "文件種類2" }
                          }),
                          _c("el-table-column", {
                            attrs: { prop: "Nm", label: "號碼" }
                          }),
                          _c("el-table-column", {
                            attrs: { prop: "IssueDt", label: "發行日期" }
                          }),
                          _c("el-table-column", {
                            attrs: { prop: "IssueCity", label: "發行城市" }
                          }),
                          _c("el-table-column", {
                            attrs: { prop: "IssueCountry", label: "發行國家" }
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "IssuingCountry",
                              label: "正在發行國家"
                            }
                          }),
                          _c("el-table-column", {
                            attrs: { prop: "Memo", label: "註釋" }
                          }),
                          _c("el-table-column", {
                            attrs: { prop: "DataDt", label: "資料日期" }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-row",
                { staticClass: "mt-8" },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "label",
                        { staticClass: "label", attrs: { for: "" } },
                        [_vm._v("制裁名單國籍：")]
                      ),
                      _c(
                        "el-table",
                        {
                          staticStyle: { width: "90%" },
                          attrs: {
                            border: "",
                            "max-height": "400",
                            data: _vm.tableData.SanList_Nationality
                          }
                        },
                        [
                          _c("el-table-column", {
                            attrs: { prop: "DataID", label: "序號" }
                          }),
                          _c("el-table-column", {
                            attrs: { prop: "ListID", label: "名單編號" }
                          }),
                          _c("el-table-column", {
                            attrs: { prop: "Nationality", label: "國籍" }
                          }),
                          _c("el-table-column", {
                            attrs: { prop: "DataDt", label: "資料日期" }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-row",
                { staticClass: "mt-8" },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "label",
                        { staticClass: "label", attrs: { for: "" } },
                        [_vm._v("制裁名單出生地：")]
                      ),
                      _c(
                        "el-table",
                        {
                          staticStyle: { width: "90%" },
                          attrs: {
                            border: "",
                            data: _vm.tableData.SanList_Place,
                            "max-height": "400"
                          }
                        },
                        [
                          _c("el-table-column", {
                            attrs: { prop: "DataID", label: "序號" }
                          }),
                          _c("el-table-column", {
                            attrs: { prop: "ListID", label: "名單編號" }
                          }),
                          _c("el-table-column", {
                            attrs: { prop: "City", label: "城市 " }
                          }),
                          _c("el-table-column", {
                            attrs: { prop: "Country", label: "出生國家" }
                          }),
                          _c("el-table-column", {
                            attrs: { prop: "StateProvince", label: "州省" }
                          }),
                          _c("el-table-column", {
                            attrs: { prop: "DataDt", label: "資料日期" }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-row",
                { staticClass: "mt-8" },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c("label", { staticClass: "label" }, [
                        _vm._v("制裁名單稱謂：")
                      ]),
                      _c(
                        "el-table",
                        {
                          staticStyle: { width: "90%" },
                          attrs: {
                            border: "",
                            data: _vm.tableData.SanList_Title,
                            "max-height": "400"
                          }
                        },
                        [
                          _c("el-table-column", {
                            attrs: { prop: "DataID", label: "序號" }
                          }),
                          _c("el-table-column", {
                            attrs: { prop: "ListID", label: "名單編號" }
                          }),
                          _c("el-table-column", {
                            attrs: { prop: "Title", label: "名單稱謂" }
                          }),
                          _c("el-table-column", {
                            attrs: { prop: "DataDt", label: "資料日期" }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-row",
                { staticClass: "mt-8" },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "label",
                        { staticClass: "label", attrs: { for: "" } },
                        [_vm._v("制裁名單類別：")]
                      ),
                      _c(
                        "el-table",
                        {
                          staticStyle: { width: "90%" },
                          attrs: {
                            border: "",
                            data: _vm.tableData.SanList_Type,
                            "max-height": "400"
                          }
                        },
                        [
                          _c("el-table-column", {
                            attrs: { prop: "DataID", label: "序號" }
                          }),
                          _c("el-table-column", {
                            attrs: { prop: "ListID", label: "名單編號" }
                          }),
                          _c("el-table-column", {
                            attrs: { prop: "ListType", label: "名單分類" }
                          }),
                          _c("el-table-column", {
                            attrs: { prop: "DataDt", label: "資料日期" }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-row",
                { staticClass: "mt-8" },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "label",
                        { staticClass: "label", attrs: { for: "" } },
                        [_vm._v("制裁名單更新時間：")]
                      ),
                      _c(
                        "el-table",
                        {
                          staticStyle: { width: "90%" },
                          attrs: {
                            border: "",
                            data: _vm.tableData.SanList_Updated,
                            "max-height": "400"
                          }
                        },
                        [
                          _c("el-table-column", {
                            attrs: { prop: "DataID", label: "序號" }
                          }),
                          _c("el-table-column", {
                            attrs: { prop: "ListID", label: "名單編號" }
                          }),
                          _c("el-table-column", {
                            attrs: { prop: "LastMtDt", label: "最後更新時間" }
                          }),
                          _c("el-table-column", {
                            attrs: { prop: "DataDt", label: "資料日期" }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm.typeNews === "searchConstr"
        ? _c(
            "div",
            [
              _c(
                "el-table",
                {
                  staticStyle: { width: "100%" },
                  attrs: { data: _vm.tableData, "max-height": "400" }
                },
                [
                  _c("el-table-column", {
                    attrs: { prop: "UniComNm", label: "廠商代碼", width: "100" }
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "CaseNm", label: "標案案號", width: "120" }
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "ComName", label: "廠商名稱", width: "150" }
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "ComLoc", label: "廠商地址", width: "200" }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "ComCountry",
                      label: "廠商國別",
                      width: "150"
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "PublicAuthNm",
                      label: "刊登機關代碼",
                      width: "150"
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "PublicAuthName",
                      label: "刊登機關名稱",
                      width: "150"
                    }
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "AuthLoc", label: "機關地址", width: "200" }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "ContactPerson",
                      label: "聯絡人",
                      width: "150"
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "ContactPhone",
                      label: "聯絡電話",
                      width: "150"
                    }
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "CaseName", label: "標案名稱", width: "150" }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "GPActStatus",
                      label: "符合政府採購法第101條所列之情形",
                      width: "400"
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "OrigPublicDt",
                      label: "原始公告日期",
                      width: "120"
                    }
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "PublicDt", label: "公告日期", width: "120" }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "EffectDt",
                      label: "拒絕往來生效日",
                      width: "120"
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "GPActPeriod",
                      label: "政府採購法第103條第一項所規定之期間",
                      width: "300"
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "DeadlineDt",
                      label: "拒絕往來截止日",
                      width: "120"
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "AppealResult",
                      label: "異議或申訴結果",
                      width: "400"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm.typeNews === "searchLabor"
        ? _c(
            "div",
            [
              _c(
                "el-table",
                {
                  staticStyle: { width: "100%" },
                  attrs: { data: _vm.tableData, "max-height": "400" }
                },
                [
                  _c("el-table-column", {
                    attrs: { prop: "MgrAuth", label: "主管機關", width: "120" }
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "PublicDt", label: "公告日期", width: "150" }
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "PunishDt", label: "處分日期", width: "150" }
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "PunishNm", label: "處分字號", width: "180" }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "UnitEntity",
                      label: "事業單位名稱",
                      width: "180"
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "NameEntity",
                      label: "負責人 / 自然人姓名",
                      width: "180"
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "VioLaw",
                      label: "違法法規法條",
                      width: "180"
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "VioCont",
                      label: "違反法規內容",
                      width: "350"
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "FineAmount",
                      label: "罰鍰金額",
                      width: "150"
                    }
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "Memo", label: "備註說明", width: "350" }
                  })
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm.typeNews === "searchEnviroment"
        ? _c(
            "div",
            [
              _c(
                "el-table",
                {
                  staticStyle: { width: "100%" },
                  attrs: { data: _vm.tableData, "max-height": "400" }
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      prop: "UnitName",
                      label: "行為人名稱",
                      width: "120"
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "PunishTime",
                      label: "違反處分時間",
                      width: "120"
                    }
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "CaseName", label: "名單名稱", width: "250" }
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "VioFact", label: "違反事實", width: "250" }
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "VioLaw", label: "違反法令", width: "300" }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "FineAmount",
                      label: "裁處金額-元",
                      width: "150"
                    }
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "IsAppeal", label: "是否訴願", width: "100" }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "AppealResult",
                      label: "訴願結果",
                      width: "300"
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "RestrictTime",
                      label: "限制時間",
                      width: "120"
                    }
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "IsImprove", label: "是否已改善" }
                  })
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ]),
    _c(
      "div",
      { staticClass: "tableDetailNews__button" },
      [
        _vm.isOperator
          ? _c(
              "el-button",
              { attrs: { type: "primary" }, on: { click: _vm.toggleDialog } },
              [
                _c("font-awesome-icon", {
                  staticStyle: { "margin-right": "5px" },
                  attrs: { icon: "plus" }
                }),
                _vm._v("建立警示名單 ")
              ],
              1
            )
          : _vm._e(),
        _c(
          "el-button",
          {
            attrs: { type: "info" },
            on: {
              click: function($event) {
                return _vm.closeDetail()
              }
            }
          },
          [_vm._v("關閉")]
        )
      ],
      1
    ),
    _vm.openPopUp
      ? _c("div", { staticClass: "WarningListPopUp" }, [
          _c("div", { staticClass: "popup" }, [
            _c("i", {
              staticClass: "el-icon-close",
              on: { click: _vm.toggleDialog }
            }),
            _c("p", [_vm._v("請選擇要新增的名單類別")]),
            _c(
              "div",
              [
                _c(
                  "el-button",
                  {
                    attrs: { type: "danger" },
                    on: {
                      click: function($event) {
                        return _vm.routerPush("people")
                      }
                    }
                  },
                  [_vm._v("人名")]
                ),
                _c(
                  "el-button",
                  {
                    attrs: { type: "danger" },
                    on: {
                      click: function($event) {
                        return _vm.routerPush("org")
                      }
                    }
                  },
                  [_vm._v("組織")]
                )
              ],
              1
            )
          ])
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }